<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="6">
        <v-radio-group
          v-model="option"
          row
          mandatory
          @change="option_change"
          dense
        >
          <v-radio label="Encours" value="1"></v-radio>
          <v-radio label="Echues a date" value="2"></v-radio>
          <v-menu
            v-model="datepicker1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                autocomplete="off"
                :value="formatDate(limite)"
                @click:clear="limite = null"
                label="Date Echéance"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                dense
                :disabled="option != 2"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="limite"
              @input="datepicker1 = false"
            ></v-date-picker>
          </v-menu>

          <v-radio label="Reglées" value="3" v-if="paye_bl"></v-radio>
          <v-radio label="Sit.Ini." value="4"> </v-radio>
          <v-radio label="Tous" value="5"> </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="9" md="9">
        <listitemspage
          :qSelect="Qselect"
          :selitem="selitem"
          :qTotal="Qtotal"
          :qSelectall="Qselectall"
          :PageResponse="'mvms'"
          :AllResponse="'allmvm'"
          :TotalResponse="'mvms_tot'"
          :headers="headers"
          :field_list="['OPR_DATE', 'NOBON']"
          :filename="'Expedition'"
          :sheetname="'Expeditions'"
          @rowselect="MvmChange"
          @open="OpenMvm"
          :showedit="false"
          :key="klist"
          :Add="false"
          :del="false"
          :Total="true"
          :laoding="loading"
          :expire1="null"
          :WhereExt="WhereExt"
          :search_elm="search"
          @search="search_maj"
          :list_options="false"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="3" md="3" v-if="paye_bl">
        <listitems
          :list="detail_list"
          :title="'Reglement'"
          :qDelete="Qdetaildelete"
          :headers="detailheaders"
          :master="false"
          :key="aff"
          :add_disable="
            mvm.statut_id >= 11 && mvm.mont_ttc - sumField('montant') > 0
          "
          :add="false"
          :del="true"
          :Update="false"
          :showedit="false"
          @open="OpenPayeForm"
          @add="refresh"
          @delete="refresh"
        >
        </listitems>
      </v-col>
    </v-row>

    <factpayeadd
      :item="paiement"
      :fact="mvm"
      :items="detail_list"
      :key="kp"
      :showpaye="!isPayeClosed"
      @close="closePayeForm"
      @change="get_data"
      @add="updateMvm"
      :payes_list="payes_list"
      :mvm="true"
    >
    </factpayeadd>
  </v-container>
</template>

<script>
import ALLMVM from "../graphql/Mouvment/ALLMVM.gql";
import MVMS_SUIVI from "../graphql/Mouvment/MVMS_SUIVI.gql";
import MVMS_SUIVI_TOTAL from "../graphql/Mouvment/MVMS_SUIVI_TOTAL.gql";
import DELETE_PAYE_DETAIL from "../graphql/Paiement/DELETE_PAYE_DETAIL.gql";
import UPDATE_MVM from "../graphql/Mouvment/UPDATE_MVM.gql";
import ALLPAYE from "../graphql/Mouvment/ALLPAYE.gql";
import { myBL } from "print/data.js";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    listitems: () => import("../components/ListItems.vue"),
    factpayeadd: () => import("../components/FactPayeAdd.vue"),
  },
  props: {
    mvm_type: Number,
  },
  data: () => ({
    klist: 100,
    option: "1",
    datepicker1: false,
    limite: null,
    selitem: {},
    headers: [
      {
        text: "Type",
        value: "type_label",
        enum: "TYPE_LABEL",
        slot: "chip",
        color: "type_color",
        list: ["R", "L"],
        selected: true,
      },
      {
        text: "Ref.",
        value: "nobon",
        enum: "NOBON",
        selected: true,
        slot: "href",
        totlib: true,
      },

      {
        text: "Date Expedition",
        value: "opr_date",
        slot: "date",
        enum: "OPR_DATE",
        selected: true,
      },
      {
        text: "Code",
        value: "tier_code",
        enum: "TIER_CODE",
        selected: false,
      },
      {
        text: "Tier",
        align: "text-left",
        value: "tier_name",
        enum: "TIERS_NAME",
        selected: true,
      },
      {
        text: "Avant le",
        value: "date_limite",
        enum: "DATE_LIMITE",
        slot: "date",
        selected: false,
        expire: true,
      },
      {
        text: "H.T",
        value: "mont_ht",
        enum: "MONT_HT",
        align: "end",
        slot: "cur",
        result: "mont_ht",
        selected: true,
      },
      {
        text: "T.T.C.",
        value: "mont_ttc",
        enum: "MONT_TTC",
        align: "end",
        slot: "cur",
        result: "mont_ttc",
        width: "150",
        selected: false,
      },
      {
        text: "Ret.",
        value: "mont_ret",
        enum: "MONT_RET",
        align: "end",
        slot: "cur",
        result: "mont_ret",
        width: "150",
        cur: true,
        hoover_list: "retours",
        hoover_name: "nobon",
        hoover_value: "mont_ht",
        selected: true,
      },
      {
        text: "Reglé",
        value: "ttc_paye",
        enum: "ttc_paye",
        align: "end",
        slot: "cur",
        selected: true,
        result: "ttc_paye",
        width: "150",
        hiden: true,
      },
      {
        text: "Facturé",
        value: "mont_fact",
        enum: "MONT_FACT",
        align: "end",
        slot: "cur",
        selected: true,
        result: "mont_fact",
        width: "150",
      },
      {
        text: "Reste",
        value: "ttc_reste",
        enum: "TTC_RESTE",
        align: "end",
        slot: "cur",
        result: "ttc_reste",
        selected: true,
        width: "150",
        hiden: true,
      },
      {
        text: "Statut",
        align: "text-left",
        value: "status_name",
        enum: "STATUS_NAME",
        selected: true,
        list: [
          "Brouillon",
          "Attente Facturation",
          "Facturée",
          "Restitué",
          "Traité",
          "Reglé Part.",
          "Reglé",
        ],
      },
    ],
    detailheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "nopaye",
        selected: true,
      },
      {
        text: "Date",
        value: "paye_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Montant",
        value: "montant_paye",
        selected: true,
        slot: "cur",
        align: "end",
      },
      {
        text: "Reglé",
        value: "montant",
        selected: true,
        slot: "cur",
        align: "end",
      },
    ],
    isMvmClosed: true,
    isPayeClosed: true,
    kp: 100,
    km: 200,
    aff: 0,
    paiement: {},
    mvm: {},
    changed: false,
    payes_list: [],
    mvm_list: [],
    loading: false,
    paye_bl: false,
    WhereExt: [],
    search: null,
  }),

  computed: {
    Qselect() {
      return MVMS_SUIVI;
    },
    Qtotal() {
      return MVMS_SUIVI_TOTAL;
    },
    Qselectall() {
      return ALLMVM;
    },
    Qdetaildelete() {
      return DELETE_PAYE_DETAIL;
    },
    detail_list: function () {
      return this.mvm.id > 0 ? this.mvm.paiements : [];
    },
  },
  watch: {
    mvm_type: {
      handler() {
        // this.mvm = {};
      },
      deep: true,
    },
  },
  created() {
    this.limite = this.$store.state.today;
  },

  mounted() {
    this.option_change();
    this.get_data();
    //si noir && autorisation
    if (
      myBL == "n" &&
      (this.$store.state.auth.includes("05021") || this.$store.state.isadmin)
    ) {
      this.paye_bl = true;
      this.headers[9].hiden = false;
      this.headers[11].hiden = false;
    }
  },

  methods: {
    refresh() {
      this.$store.dispatch("Changed", true);
      this.get_data();
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    option_change() {
      switch (this.option) {
        case "1":
          this.WhereExt = [
            { column: "TTC_RESTE", operator: "GT", value: 0 },
            {
              OR: [
                { column: "DECISION_RET", operator: "IS_NULL" },
                { column: "DECISION_RET", value: 2 },
              ],
            },
            { column: "INI", value: "0" },
            { column: "STATUT_ID", operator: "GTE", value: "11" },
            {
              column: "MVM_TYPE",
              operator: "IN",
              value:
                this.mvm_type == 1 || this.mvm_type == 2
                  ? ["1", "2"]
                  : ["3", "4"],
            },
          ];

          break;
        case "2":
          this.WhereExt = [
            {
              OR: [
                { column: "DECISION_RET", operator: "IS_NULL" },
                { column: "DECISION_RET", value: 2 },
              ],
            },
            { column: "DATE_LIMITE", operator: "LTE", value: this.limite },
            { column: "TTC_RESTE", operator: "GT", value: 0 },
            { column: "INI", value: "0" },
            { column: "STATUT_ID", operator: "GTE", value: "11" },
            {
              column: "MVM_TYPE",
              operator: "IN",
              value:
                this.mvm_type == 1 || this.mvm_type == 2
                  ? ["1", "2"]
                  : ["3", "4"],
            },
          ];

          break;
        case "3":
          this.WhereExt = [
            {
              OR: [
                { column: "DECISION_RET", operator: "IS_NULL" },
                { column: "DECISION_RET", value: 2 },
              ],
            },
            { column: "MONT_TTC", operator: "GT", value: 0 },
            { column: "TTC_PAYE", operator: "GT", value: 0 },
            { column: "INI", value: "0" },
            { column: "STATUT_ID", operator: "GTE", value: "11" },
            {
              column: "MVM_TYPE",
              operator: "IN",
              value:
                this.mvm_type == 1 || this.mvm_type == 2
                  ? ["1", "2"]
                  : ["3", "4"],
            },
          ];

          break;
        case "4":
          this.WhereExt = [
            {
              OR: [
                { column: "DECISION_RET", operator: "IS_NULL" },
                { column: "DECISION_RET", value: 2 },
              ],
            },
            { column: "INI", value: "1" },
            { column: "STATUT_ID", value: "11" },
            {
              column: "MVM_TYPE",
              operator: "IN",
              value:
                this.mvm_type == 1 || this.mvm_type == 2
                  ? ["1", "2"]
                  : ["3", "4"],
            },
          ];

          break;

        case "5":
          this.WhereExt = [
            {
              OR: [
                { column: "DECISION_RET", operator: "IS_NULL" },
                { column: "DECISION_RET", value: 2 },
              ],
            },
            {
              column: "MVM_TYPE",
              operator: "IN",
              value:
                this.mvm_type == 1 || this.mvm_type == 2
                  ? ["1", "2"]
                  : ["3", "4"],
            },
          ];

          break;
        default:
          break;
      }
      this.klist++;
    },
    async get_data() {
      let v;
      this.loading = true;

      v = {
        VarScope: 0,
        TypeScope: this.mvm_type == 3 || this.mvm_type == 4 ? 3 : 1,
      };
      let r = await this.requette(ALLPAYE, v);

      if (r) this.payes_list = r.allpaye;
      this.headers[4].text =
        this.mvm_type == 3 || this.mvm_type == 4 ? "Client" : "Fournisseur";

      this.aff++;
    },

    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    updateMvm() {
      if (this.mvm.fact_id) {
        this.$apollo
          .mutate({
            mutation: UPDATE_MVM,
            variables: {
              mvm: {
                id: this.mvm.id,
                fact_id: null,
                write_uid: this.$store.state.me.id,
              },
            },
          })
          .then(() => {
            this.mvm = {};
            this.aff++;
          })
          .catch((error) => {
            this.loading = false;
            this.snackbar_text = error.message;
            this.snackbar_color = "error";
            this.snackbar = true;
          });
      }
    },
    search_maj(item) {
      this.search = item;
    },
    sumField(key) {
      // sum data in give key (property)
      return this.detail_list.reduce((a, b) => a + (b[key] || 0), 0);
    },
    OpenPayeForm(item) {
      this.kp++;
      this.paiement = item;
      this.isPayeClosed = false;
    },
    closePayeForm() {
      this.isPayeClosed = true;
    },
    MvmChange(item) {
      this.aff++;
      this.selitem = item;
      this.mvm = item;
    },
    async OpenMvm(item) {
      // verifier autorisation avant
      let auth =
        (item.mvm_type == 1 && this.$store.state.auth.includes("01007")) ||
        (item.mvm_type == 2 && this.$store.state.auth.includes("01008")) ||
        (item.mvm_type == 3 && this.$store.state.auth.includes("01009")) ||
        (item.mvm_type == 4 && this.$store.state.auth.includes("01010")) ||
        this.$store.state.isadmin;
      if (auth)
        await this.$router
          .push({
            name:
              item.mvm_type == 1
                ? "receptions_e"
                : item.mvm_type == 2
                ? "retours_f"
                : item.mvm_type == 3
                ? "livraisons"
                : item.mvm_type == 4
                ? "retours_c"
                : "",
            params: {
              mvm: item,
              name:
                item.mvm_type == 1 || item.mvm_type == 2
                  ? "suivi_rcpts"
                  : "suivi_bls",
            },
          })
          .catch(() => {});
    },
  },
};
</script>
